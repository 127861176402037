import React, { useState } from "react";
import { DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Image,
  Form,
  DatePicker,
  Switch,
  Select,
  Modal,
} from "antd";

import { useHistory } from "react-router-dom";

import { uploadRewardImage, createReward } from "../services/rewards";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const imageDf = {
  aspectRatio: "1/1",
  backgroundColor: "#eee",
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 10,
  borderRadius: 8,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  overflow: "hidden",
};

export default function RewardCreate() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loadingMain, setLoadingMain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [imageCoverUrl, setImageCoverUrl] = useState(null);
  const [image1Url, setImage1Url] = useState(null);
  const [image2Url, setImage2Url] = useState(null);
  const [image3Url, setImage3Url] = useState(null);
  const [image4Url, setImage4Url] = useState(null);
  const [image5Url, setImage5Url] = useState(null);
  const [selectImageId, setSelectImageId] = useState(0);

  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const onUploadClick = (imgId) => {
    setSelectImageId(imgId);
    const input = document.getElementById("file-input");
    if (input) {
      input.click();
    }
  };

  const UploadImage = async (e) => {
    if (selectImageId === 0) setLoading(true);
    if (selectImageId === 1) setLoading1(true);
    if (selectImageId === 2) setLoading2(true);
    if (selectImageId === 3) setLoading3(true);
    if (selectImageId === 4) setLoading4(true);
    if (selectImageId === 5) setLoading5(true);

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const resp = await uploadRewardImage(formData);
    document.getElementById("file-input").value = "";
    if (!resp._error) {
      if (selectImageId === 0) {
        setLoading(false);
        setImageCoverUrl(resp.data);
      }
      if (selectImageId === 1) {
        setLoading1(false);
        setImage1Url(resp.data);
      }
      if (selectImageId === 2) {
        setLoading2(false);
        setImage2Url(resp.data);
      }
      if (selectImageId === 3) {
        setLoading3(false);
        setImage3Url(resp.data);
      }
      if (selectImageId === 4) {
        setLoading4(false);
        setImage4Url(resp.data);
      }
      if (selectImageId === 5) {
        setLoading5(false);
        setImage5Url(resp.data);
      }
    }
  };

  const submitReward = async () => {
    setLoadingMain(true);
    form
      .validateFields()
      .then(async (values) => {
        if (imageCoverUrl === null) {
          Modal.error({
            title: "กรุณาเลือกรูปภาพปกรางวัล",
            content: "กรุณาเลือกรูปภาพปกรางวัล และลองใหม่อีกครั้ง!",
          });
          return;
        }
        const data = {
          name: values.name,
          use_point: Number(values.use_point),
          amount: Number(values.amount),
          start_datetime: values.rewardTime[0]._d.toJSON(),
          end_datetime: values.rewardTime[1]._d.toJSON(),
          is_per_user: values.is_per_user,
          condition: values.condition,
          image_cover_url: imageCoverUrl || "",
          image_slide1_url: image1Url || "",
          image_slide2_url: image2Url || "",
          image_slide3_url: image3Url || "",
          image_slide4_url: image4Url || "",
          image_slide5_url: image5Url || "",
          enable: Number(values.enable),
        };
        const resp = await createReward(data);
        setLoadingMain(false);
        if (!resp._error) {
          history.push("/rewards");
        }
      })
      .catch((info) => {
        setLoadingMain(false);
      });
  };

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24} md={24} xl={24}>
        <Card
          bordered={false}
          className="criclebox tablespace mb-24"
          title={"เพิ่มรางวัล"}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} md={24} xl={14}>
              <Form
                {...formItemLayout}
                layout={"horizontal"}
                form={form}
                initialValues={{
                  enable: true,
                }}
              >
                <Form.Item
                  label="ชื่อรางวัล"
                  name="name"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุชื่อ!",
                    },
                  ]}
                >
                  <Input placeholder="ระบุชื่อ" />
                </Form.Item>

                <Form.Item
                  label="คะแนนที่ใช้แลก"
                  name="use_point"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุคะแนนที่ใช้แลก!",
                    },
                  ]}
                >
                  <Input
                    placeholder="ระบุคะแนนที่ใช้แลก"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>

                <Form.Item
                  label="จำนวนสิทธิ์"
                  name="amount"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุจำนวนสิทธิ์!",
                    },
                  ]}
                >
                  <Input
                    placeholder="ระบุจำนวนสิทธิ์"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>

                <Form.Item
                  label="วันเริ่มต้น-สิ้นสุด"
                  name="rewardTime"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุวันเริ่มต้น-สิ้นสุด!",
                    },
                  ]}
                >
                  <RangePicker
                    showTime={{
                      format: "HH:mm",
                    }}
                    placeholder={["วันเวลาเริ่มต้น", "วันเวลาสิ้นสุด"]}
                    format="YYYY-MM-DD HH:mm"
                    // onChange={onChangeOnlineTime}
                    // onOk={onOkOnlineTime}
                  />
                </Form.Item>

                <Form.Item
                  label="ข้อกำหนดการแลก"
                  name="is_per_user"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุข้อกำหนดการแลก!",
                    },
                  ]}
                >
                  <Select placeholder="ข้อกำหนดการแลก">
                    <Select.Option key={"opt1"} value={1}>
                      แลกได้ 1 คน ต่อ 1 ครั้ง
                    </Select.Option>
                    <Select.Option key={"opt2"} value={0}>
                      แลกได้ตลอดถ้ามีคะแนน
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label="เงื่อนไขเพิ่มเติม" name="condition">
                  <TextArea rows={4} placeholder="เงื่อนไขเพิ่มเติม" />
                </Form.Item>

                <Form.Item
                  valuePropName="checked"
                  label="เปิด/ปิดการใช้แสดง"
                  name="enable"
                >
                  <Switch
                    checkedChildren="เปิด "
                    unCheckedChildren="ปิด "
                    defaultChecked
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} md={24} xl={10}>
              <Row>
                <Col xs={12} md={12} xl={12}>
                  {!imageCoverUrl ? (
                    <div style={imageDf}>
                      <Button
                        loading={loading}
                        onClick={() => onUploadClick(0)}
                      >
                        เพิ่มภาพปก*
                      </Button>
                    </div>
                  ) : (
                    <div style={imageDf}>
                      <Button
                        onClick={() => setImageCoverUrl(null)}
                        style={{
                          position: "absolute",
                          zIndex: 9,
                          right: 20,
                          bottom: 20,
                          width: 42,
                        }}
                      >
                        <DeleteOutlined />
                      </Button>

                      <Image
                        style={{
                          aspectRatio: "1/1",
                          objectFit: "cover",
                        }}
                        src={imageCoverUrl}
                        preview={false}
                      />
                    </div>
                  )}
                </Col>
                <Col xs={12} md={12} xl={12}>
                  {!image1Url ? (
                    <div style={imageDf}>
                      <Button
                        loading={loading1}
                        onClick={() => onUploadClick(1)}
                      >
                        เพิ่มภาพ
                      </Button>
                    </div>
                  ) : (
                    <div style={imageDf}>
                      <Button
                        onClick={() => setImage1Url(null)}
                        style={{
                          position: "absolute",
                          zIndex: 9,
                          right: 20,
                          bottom: 20,
                          width: 42,
                        }}
                      >
                        <DeleteOutlined />
                      </Button>

                      <Image
                        style={{
                          aspectRatio: "1/1",
                          objectFit: "cover",
                        }}
                        src={image1Url}
                        preview={false}
                      />
                    </div>
                  )}
                </Col>
                <Col xs={12} md={12} xl={12}>
                  {!image2Url ? (
                    <div style={imageDf}>
                      <Button
                        loading={loading2}
                        onClick={() => onUploadClick(2)}
                      >
                        เพิ่มภาพ
                      </Button>
                    </div>
                  ) : (
                    <div style={imageDf}>
                      <Button
                        onClick={() => setImage2Url(null)}
                        style={{
                          position: "absolute",
                          zIndex: 9,
                          right: 20,
                          bottom: 20,
                          width: 42,
                        }}
                      >
                        <DeleteOutlined />
                      </Button>

                      <Image
                        style={{
                          aspectRatio: "1/1",
                          objectFit: "cover",
                        }}
                        src={image2Url}
                        preview={false}
                      />
                    </div>
                  )}
                </Col>
                <Col xs={12} md={12} xl={12}>
                  {!image3Url ? (
                    <div style={imageDf}>
                      <Button
                        loading={loading3}
                        onClick={() => onUploadClick(3)}
                      >
                        เพิ่มภาพ
                      </Button>
                    </div>
                  ) : (
                    <div style={imageDf}>
                      <Button
                        onClick={() => setImage3Url(null)}
                        style={{
                          position: "absolute",
                          zIndex: 9,
                          right: 20,
                          bottom: 20,
                          width: 42,
                        }}
                      >
                        <DeleteOutlined />
                      </Button>

                      <Image
                        style={{
                          aspectRatio: "1/1",
                          objectFit: "cover",
                        }}
                        src={image3Url}
                        preview={false}
                      />
                    </div>
                  )}
                </Col>
                <Col xs={12} md={12} xl={12}>
                  {!image4Url ? (
                    <div style={imageDf}>
                      <Button
                        loading={loading4}
                        onClick={() => onUploadClick(4)}
                      >
                        เพิ่มภาพ
                      </Button>
                    </div>
                  ) : (
                    <div style={imageDf}>
                      <Button
                        onClick={() => setImage4Url(null)}
                        style={{
                          position: "absolute",
                          zIndex: 9,
                          right: 20,
                          bottom: 20,
                          width: 42,
                        }}
                      >
                        <DeleteOutlined />
                      </Button>

                      <Image
                        style={{
                          aspectRatio: "1/1",
                          objectFit: "cover",
                        }}
                        src={image4Url}
                        preview={false}
                      />
                    </div>
                  )}
                </Col>
                <Col xs={12} md={12} xl={12}>
                  {!image5Url ? (
                    <div style={imageDf}>
                      <Button
                        loading={loading5}
                        onClick={() => onUploadClick(5)}
                      >
                        เพิ่มภาพ
                      </Button>
                    </div>
                  ) : (
                    <div style={imageDf}>
                      <Button
                        onClick={() => setImage5Url(null)}
                        style={{
                          position: "absolute",
                          zIndex: 9,
                          right: 20,
                          bottom: 20,
                          width: 42,
                        }}
                      >
                        <DeleteOutlined />
                      </Button>

                      <Image
                        style={{
                          aspectRatio: "1/1",
                          objectFit: "cover",
                        }}
                        src={image5Url}
                        preview={false}
                      />
                    </div>
                  )}
                </Col>
                <input
                  type="file"
                  id="file-input"
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg"
                  onChange={UploadImage}
                />
              </Row>
            </Col>
          </Row>
          <Row gutter={[24, 0]} className="mb-24">
            <Col xs={24} md={24} xl={24}>
              <Button
                type="primary"
                loading={loadingMain}
                onClick={() => submitReward()}
                style={{
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <CheckCircleOutlined /> ยืนยันเพิ่มรางวัล
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
