import React, { useState } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Card,
  Alert,
} from "antd";

import { adminSignIn } from "../services/auth";

const { Title } = Typography;
const { Header, Content } = Layout;

export default function SignIn() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsError(false);
    setIsLoading(true);
    const data = {
      mobile: values.Username,
      password: values.Password,
    };
    const resSignOn = await adminSignIn(data);
    setIsLoading(false);
    if (resSignOn.code === "00000") {
      setIsError(false);
      window.location.href = "/customers";
    } else {
      setIsError(true);
      return;
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <Layout
        className="layout-default layout-signin"
        style={{ minHeight: "100vh" }}
      >
        <Header>
          <div className="header-col header-brand">
            <h3>OOTD-FITTING ADMIN</h3>
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]}>
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 8, offset: 8 }}
              md={{ span: 8, offset: 8 }}
            >
              <Card style={{ backgroundColor: "white", marginTop: 20 }}>
                <Title level={2} className="mb-15 text-center">
                  Sign In
                </Title>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    label="Username"
                    name="Username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input placeholder="Username" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>

                  {isError && (
                    <Alert
                      message="Username/Password ไม่ถูกต้อง!"
                      type="error"
                      showIcon
                      style={{ marginBottom: 8 }}
                    />
                  )}

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={isLoading}
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
