import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Avatar,
  Input,
  Space,
  Button,
  Form,
  Alert,
  Modal,
  Spin,
  List,
  Typography,
  Badge,
  notification,
} from "antd";

import { getAllCustomers } from "../services/customers";
import { potinAdminEarnCsv } from "../services/point";

const { Text } = Typography;

export default function PointImportCsv() {
  const [customers, setCustomers] = useState([]);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isPassCheck, setIsPassCheck] = useState(false);
  const [array, setArray] = useState([]);
  const [proceesAlert, setProcessAlert] = useState([]);

  useEffect(() => {
    InitData();
  }, []);

  const InitData = async () => {
    const resp = await getAllCustomers();
    if (!resp._error) {
      setCustomers(resp.data);
    }
  };

  const renderProfile = (record) => {
    const item = customers.filter((v) => v.mobile === record.mobile);
    if (item.length <= 0) {
      return <Text style={{ color: "red" }}>ไม่พบเบอร์นี้ในระบบ</Text>;
    }
    return (
      <div>
        <List.Item.Meta
          avatar={<Avatar src={item[0].line_user_profile} />}
          title={item[0].line_user_name}
          description={
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Badge
                count={`${item[0].point} คะแนน`}
                style={{
                  backgroundColor: "#1890ff",
                }}
              />
            </div>
          }
        />
      </div>
    );
  };

  const columns = [
    {
      title: "#",
      key: "index",
      dataIndex: "index",
      width: 80,
      ellipsis: true,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Profile",
      key: "profile",
      dataIndex: "profile",
      width: 180,
      ellipsis: true,
      render: (id, record, index) => renderProfile(record),
    },
    {
      title: "Mobile",
      key: "mobile",
      dataIndex: "mobile",
      width: 180,
      ellipsis: true,
    },
    {
      title: "Point",
      key: "point",
      dataIndex: "point",
      width: 180,
      ellipsis: true,
    },
    {
      title: "Note",
      key: "note",
      dataIndex: "note",
      width: 180,
      ellipsis: true,
    },
  ];

  const fileReader = new FileReader();

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    const checkNullRow = csvRows.filter((v) => v.split(",").length > 2);
    const array = checkNullRow.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header.trim()] = values[index].trim();
        return object;
      }, {});
      return obj;
    });

    proccesingData(csvHeader, array);
  };

  const proccesingData = (csvHeader, arrayData) => {
    // Check header file
    var headerAlert = [];
    var headerErrorCount = 0;
    if (csvHeader.length !== 3) {
      headerErrorCount++;
      headerAlert.push(
        `จำนวนคอลัมนไม่ถูกต้อง (คาดหวัง 3 : ผลลัพธ์ ${csvHeader.length})`
      );
    } else {
      const checkFields = ["mobile", "point", "note"];
      for (let i = 0; i < csvHeader.length; i++) {
        if (csvHeader[i].trim() !== checkFields[i]) {
          headerErrorCount++;
          headerAlert.push(
            `ชื่อคอลัมน์ที่ ${i + 1} ไม่ถูกต้อง (คาดหวัง ${
              checkFields[i]
            } : ผลลัพธ์ ${csvHeader[i]})`
          );
        }
      }
    }
    if (headerErrorCount !== 0) {
      setProcessAlert(headerAlert);
      setLoading(false);
      return false;
    }

    var dataAlert = [];
    var dataErrorCount = 0;
    for (let d = 0; d < arrayData.length; d++) {
      const regexMobile = /^0\d{9}$/;
      if (!regexMobile.test(arrayData[d].mobile)) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"mobile"} เบอร์มือถือไม่ถูกต้อง [${arrayData[d].mobile}]`
        );
      }

      const regexPoint = /^[1-9]\d*(\.\d+)?$/;
      if (!regexPoint.test(arrayData[d].point)) {
        dataErrorCount++;
        dataAlert.push(
          `พบข้อผิดพลาด ข้อมูลแถวที่ ${
            d + 1
          } คอลัมน์ ${"point"} คะแนนไม่ถูกต้อง [${arrayData[d].point}]`
        );
      }
    }
    if (dataErrorCount !== 0) {
      setProcessAlert(dataAlert);
      setLoading(false);
      return false;
    }
    setArray(arrayData);
    setLoading(false);
    setIsPassCheck(true);
  };

  const handleOnChange = (e) => {
    setArray([]);
    setVisible(true);
    setLoading(true);
    if (e.target.files[0]) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        const textAddHeader = `mobile,point,note\n` + text;
        csvFileToArray(textAddHeader);
      };
      fileReader.readAsText(e.target.files[0]);
    }
  };

  const handleCancel = () => {
    setProcessAlert([]);
    setIsPassCheck(false);
    setLoading(false);
    setVisible(false);
  };

  const openNotification = () => {
    notification.success({
      message: "ดำเนินการเรียบร้อย!",
      description: "ดำเนินการนำเข้าคะแนนเรียบร้อยแล้ว!",
      onClick: () => {},
    });
  };

  const submitImportData = async () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const data = {
          importName: values.import_name,
          csvEarnList: array.map((v) => {
            return { ...v, point: Number(v.point) };
          }),
        };
        const resp = await potinAdminEarnCsv(data);
        if (!resp._error) {
          setTimeout(() => {
            form.resetFields();
            setArray([]);
            setLoading(false);
            openNotification();
          }, 1000);
        } else {
          setLoading(false);
        }
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={"นำเข้าไฟล์ (.csv)"}
          >
            <Row gutter={[24, 0]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 12, offset: 6 }}
                md={{ span: 12, offset: 6 }}
              >
                <Card className="mb-24">
                  <Form form={form} layout="vertical" className="row-col">
                    <Form.Item
                      label="ชื่อการนำเข้า"
                      name="import_name"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาตั้งชื่อการนำเข้า!",
                        },
                      ]}
                    >
                      <Input placeholder="ชื่อการนำเข้า" />
                    </Form.Item>

                    <Form.Item label="กรุณาเลือกไฟล์ (.csv)" name="file">
                      <Input
                        type={"file"}
                        placeholder="ระบุไฟล์ .csv"
                        accept={".csv"}
                        onChange={handleOnChange}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                        onClick={submitImportData}
                        disabled={array.length <= 0}
                        loading={loading}
                      >
                        {"ยืนยันนำเข้าข้อมูล"}
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
            </Row>
            <div className="table-responsive">
              <Table
                sticky
                dataSource={array}
                columns={columns}
                className="ant-border-space"
                rowKey="mobile"
                pagination={{
                  defaultPageSize: 1000,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        centered
        width="80%"
        open={visible}
        title={"ตรวจสอบไฟล์ (.csv)"}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleCancel}
          >
            {loading ? "กำลังตรวจสอบ" : "ปิดผลการตรวจสอบ"}
          </Button>,
        ]}
      >
        {loading && (
          <div>
            <Space size="middle">
              <Spin size="small" />
            </Space>{" "}
            กรุณารอสักครู่ กำลังตรวจข้อมูล . . .
          </div>
        )}
        {!loading && <p>ผลการตรวจข้อมูล</p>}
        {proceesAlert.map((v, index) => (
          <Alert key={index} message={v} type="error" />
        ))}
        {isPassCheck && (
          <Alert
            message={
              "ข้อมูลพร้อมนำเข้า กรุณาตรวจสอบข้อมูลในตาราง และกดปุ่มยืนยันนำเข้าข้อมูล"
            }
            type="success"
          />
        )}
      </Modal>
    </div>
  );
}
