import React, { useState, useRef, useEffect } from "react";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { Row, Col, Card, Table, Avatar, Input, Space, Button } from "antd";
import Highlighter from "react-highlight-words";
import { getAllCustomers } from "../services/customers";

export default function Customers() {
  const [customers, setCustomers] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    InitData();
  }, []);

  const InitData = async () => {
    const resp = await getAllCustomers();
    if (!resp._error) {
      setCustomers(resp.data);
    }
  };

  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Avatar
          style={{
            backgroundColor: "#CCCCCC",
          }}
          src={avatar}
        />
      );
    }
    return (
      <Avatar
        style={{
          backgroundColor: "#CCCCCC",
        }}
        icon={<UserOutlined />}
      />
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Profile",
      dataIndex: "profile",
      key: "profile",
      width: 120,
      render: (_, record) =>
        customers.length >= 1 ? renderAvatar(record.profile) : null,
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "display_name",
      ...getColumnSearchProps("display_name"),
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      sortOrder:
        sortedInfo.columnKey === "display_name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      width: 220,
      ...getColumnSearchProps("mobile"),
      sorter: (a, b) => a.mobile.localeCompare(b.mobile),
      sortOrder: sortedInfo.columnKey === "mobile" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => (
        <>{record.mobile.length > 0 ? record.mobile : "-"}</>
      ),
    },

    {
      title: "Point",
      key: "point",
      dataIndex: "point",
      width: 220,
    },
    {
      title: "Verified",
      key: "is_verified",
      dataIndex: "is_verified",
      width: 180,
      ellipsis: true,
      render: (_, record) => <>{record.is_verified === 1 ? "true" : "false"}</>,
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="ลูกค้า"
          >
            <div className="table-responsive">
              <Table
                sticky
                dataSource={customers}
                columns={columns}
                onChange={handleChange}
                // scroll={{ x: 3800 }}
                className="ant-border-space"
                rowKey="id"
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
