import { RunAPI } from "../utils/RunApi";
import { APPCONFIG } from "../Config";
import jwtDecode from "jwt-decode";

export const adminSignIn = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/admin/authentication`;

  const apikey = `${APPCONFIG.APIKEY}`;
  const res = await RunAPI("POST", url, data, apikey);
  if (!res._error && res.data) {
    if (res.code === "00000") {
      const JWT = res.data["access-token"];
      const RefreshToken = res.data["refresh-token"];
      const decoded = jwtDecode(JWT);
      localStorage.setItem("@aid", JWT);
      localStorage.setItem("@rid", RefreshToken);
      localStorage.setItem("@amdid", JSON.stringify(decoded["admin_id"]));
      localStorage.setItem("@amdn", decoded["display_name"]);
    }
  }
  return res;
};
