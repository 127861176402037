import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Select,
  Modal,
  List,
  Avatar,
  Space,
  Typography,
  Badge,
} from "antd";
import { getAllCustomers } from "../../services/customers";

const { Option } = Select;
const { Text, Link } = Typography;

export default function UserInfoModal({
  isModalOpen,
  handleCancel,
  onSelected,
  onUnSelected,
}) {
  const [customers, setCustomers] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filter, setFilter] = useState("mobile");
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (isModalOpen) {
      InitData();
    }
  }, [isModalOpen]);

  useEffect(() => {
    setKeyword("");
    setSearchData(customers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCancel]);

  const InitData = async () => {
    const resp = await getAllCustomers();
    if (!resp._error) {
      setCustomers(resp.data);
      setSearchData(resp.data);
    }
  };

  const selectBefore = (
    <Select
      defaultValue="mobile"
      onChange={(e) => {
        setFilter(e);
        searchWithKeyword(keyword);
      }}
    >
      <Option key={"mobile"} value="mobile">
        Mobile
      </Option>
      <Option key={"name"} value="name">
        Name
      </Option>
    </Select>
  );

  const searchWithKeyword = (keyword) => {
    if (filter === "mobile") {
      const results = customers.filter((cust) => {
        if (keyword === "") return customers;
        return cust.mobile.toLowerCase().includes(keyword.toLowerCase());
      });
      setSearchData(results);
    } else {
      const results = customers.filter((cust) => {
        if (keyword === "") return customers;
        return cust.line_user_name
          .toLowerCase()
          .includes(keyword.toLowerCase());
      });
      setSearchData(results);
    }
  };

  return (
    <>
      <Modal
        title={
          <div>
            <div>Customers</div>
            <Space.Compact
              style={{
                marginTop: 10,
                width: "100%",
              }}
            >
              <Input
                addonBefore={selectBefore}
                allowClear
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                  searchWithKeyword(e.target.value);
                }}
              />
              <Button type="primary" onClick={() => searchWithKeyword(keyword)}>
                ค้นหา
              </Button>
            </Space.Compact>
          </div>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        footer={[
          <Button key={"handleCancelBtn"} onClick={onUnSelected}>
            ยกเลิก
          </Button>,
        ]}
      >
        <div id="scrollableDiv">
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={searchData}
            renderItem={(item, index) => (
              <List.Item
                actions={[<Link onClick={() => onSelected(item)}>เลือก</Link>]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.profile} />}
                  title={item.display_name}
                  description={
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={{ marginRight: 4 }}>{item.mobile}</Text>
                      <Badge
                        count={`${item.point} คะแนน`}
                        style={{
                          backgroundColor: "#1890ff",
                        }}
                      />
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </>
  );
}
