import { APPCONFIG } from "../Config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";

export const potinAdminEarn = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/point/admin/earn`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const pointAdminBurn = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/point/admin/burn`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const potinAdminEarnCsv = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/point/admin/earn/csv`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const getAdminEarnCsvImport = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/point/admin/earn/csv`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getAdminEarnCsvImportDetail = async (importId) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/point/admin/earn/csv/${importId}`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};
