import { APPCONFIG } from "../Config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";
import { RunApiRefreshTokenMultipart } from "../utils/RunApiRefreshTokenMultipart";

export const getAllRewards = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/reward`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const createReward = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/reward/create`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const updateReward = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/reward/update`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};

export const switchReward = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/reward/switch`;
  const res = await RunApiRefreshToken("PUT", url, data);
  return res;
};

export const deleteReward = async (reward_id) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/reward/${reward_id}`;
  const res = await RunApiRefreshToken("DELETE", url, {});
  return res;
};

export const uploadRewardImage = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/reward/upload`;
  const res = await RunApiRefreshTokenMultipart("POST", url, data);
  return res;
};
