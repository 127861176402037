import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo-x.png";
import {
  GiftOutlined,
  TeamOutlined,
  SwapOutlined,
  FieldTimeOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>OOTD-FITTING</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {/* <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item className="menu-item-header" key="menu-item-customers">
          Customers
        </Menu.Item>
        <Menu.Item key="customers">
          <NavLink to="/customers">
            <span
              className="icon"
              style={{
                background: page === "customers" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">ลูกค้า</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="menu-item-rewards">
          Rewards
        </Menu.Item>
        <Menu.Item key="rewards">
          <NavLink to="/rewards">
            <span
              className="icon"
              style={{
                background: page === "rewards" ? color : "",
              }}
            >
              <GiftOutlined />
            </span>
            <span className="label">รางวัล</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          className="menu-item-header"
          key="menu-item-point-transection"
        >
          Point Management
        </Menu.Item>
        <Menu.Item key="point-transection">
          <NavLink to="/point-transection">
            <span
              className="icon"
              style={{
                background: page === "point-transection" ? color : "",
              }}
            >
              <FieldTimeOutlined />
            </span>
            <span className="label">ประวัติคะแนน</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="point-management">
          <NavLink to="/point-management">
            <span
              className="icon"
              style={{
                background: page === "point-management" ? color : "",
              }}
            >
              <SwapOutlined />
            </span>
            <span className="label">เพิ่ม/ลดคะแนน</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="point-csv">
          <NavLink to="/point-csv">
            <span
              className="icon"
              style={{
                background: page === "point-csv" ? color : "",
              }}
            >
              <FileExcelOutlined />
            </span>
            <span className="label">เพิ่มคะแนน (.csv)</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="2">
          <NavLink to="/tables">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Tables</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/billing">
            <span
              className="icon"
              style={{
                background: page === "billing" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Billing</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/rtl">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">RTL</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="5">
          Account Pages
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/sign-in">
            <span className="icon">
              {" "}
              <TeamOutlined />
            </span>
            <span className="label">Sign In</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/sign-up">
            <span className="icon">
              {" "}
              <TeamOutlined />
            </span>
            <span className="label">Sign Up</span>
          </NavLink>
        </Menu.Item> */}
      </Menu>
      {/* <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            <TeamOutlined />
          </span>
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button>
        </div>
      </div> */}
    </>
  );
}

export default Sidenav;
