import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Form,
  Select,
  Avatar,
  Badge,
  Typography,
  notification,
  Spin,
} from "antd";

import { CheckCircleOutlined } from "@ant-design/icons";

import UserInfoModal from "../components/users/UserInfoModal";
import { pointAdminBurn, potinAdminEarn } from "../services/point";

const { Text, Title } = Typography;

const openNotification = () => {
  notification.success({
    message: "ดำเนินการเรียบร้อย!",
    description: "ดำเนินการเพิ่มหรือลดคะแนนเรียบร้อยแล้ว!",
    onClick: () => {},
  });
};

export default function PointManagement() {
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [userInfoSelected, setUserInfoSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEarn, setIsEarn] = useState(true);
  const [point, setPoint] = useState("");
  const [note, setNote] = useState("");

  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const handleUserSelected = (userInfo) => {
    setUserInfoSelected(userInfo);
    const values = {
      mobile: userInfo.mobile,
    };
    form.setFieldsValue(values);
    setIsUserModalOpen(false);
  };

  const submitPoint = async () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const data = {
          mobile: userInfoSelected.mobile,
          point: Number(point),
          note: note,
        };
        if (isEarn) {
          const resp = await potinAdminEarn(data);
          setLoading(false);
          if (!resp._error) {
            form.resetFields();
            openNotification();
            setIsEarn(true);
            setPoint("");
            setNote("");
            setUserInfoSelected(null);
          }
        } else {
          const resp = await pointAdminBurn(data);
          setLoading(false);
          if (!resp._error) {
            form.resetFields();
            openNotification();
            setIsEarn(true);
            setPoint("");
            setNote("");
            setUserInfoSelected(null);
          }
        }
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24} md={24} xl={24}>
        <Card
          bordered={false}
          className="criclebox tablespace mb-24"
          title={"เพิ่ม/ลดคะแนน"}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} md={12} xl={12}>
              <Form
                {...formItemLayout}
                layout={"horizontal"}
                form={form}
                initialValues={{
                  is_earn: 1,
                }}
              >
                <Form.Item
                  label="เบอร์มือถือ"
                  name="mobile"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุเบอร์มือถือ!",
                    },
                  ]}
                >
                  <Input
                    placeholder="ระบุเบอร์มือถือ"
                    readOnly
                    onClick={() => setIsUserModalOpen(true)}
                  />
                </Form.Item>

                <Form.Item
                  label="ประเภท"
                  name="is_earn"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุข้อกำหนดการแลก!",
                    },
                  ]}
                >
                  <Select
                    placeholder="ข้อกำหนดการแลก"
                    onChange={(e) => setIsEarn(Boolean(e))}
                  >
                    <Select.Option key={"earn"} value={1}>
                      เพิ่มคะแนนสะสม
                    </Select.Option>
                    <Select.Option key={"burn"} value={0}>
                      ลดคะแนนสะสม
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="คะแนน"
                  name="use_point"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุคะแนน!",
                    },
                    {
                      validator: async (_, val) => {
                        if (val <= 0) {
                          return Promise.reject(
                            new Error("ต้องเป็นค่าตัวเลขที่มากกว่า 0")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="ระบุคะแนน"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setPoint(e.target.value)}
                  />
                </Form.Item>

                <Form.Item label="หมายเหตุ" name="note">
                  <Input
                    placeholder="ระบุหมายเหตุ (ถ้ามี)"
                    onChange={(e) => setNote(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} md={12} xl={12}>
              {!userInfoSelected && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 40,
                  }}
                >
                  <Spin tip="กรุณากรอกข้อมูล เพื่อประมวลผล . . ." />
                </div>
              )}
              {userInfoSelected && (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Avatar
                      shape="square"
                      style={{ borderRadius: 10 }}
                      size={100}
                      src={userInfoSelected.line_user_profile}
                    />
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 20,
                      }}
                    >
                      <Title
                        style={{ marginTop: 10, marginBottom: 0 }}
                        level={4}
                      >
                        {userInfoSelected.line_user_name}
                      </Title>
                      <Text strong>{userInfoSelected.mobile}</Text>
                      <Text>{userInfoSelected.email}</Text>
                      <Badge
                        count={`${userInfoSelected.point} คะแนน`}
                        style={{
                          backgroundColor: "#1890ff",
                        }}
                      />
                    </div>
                  </div>
                  <Title
                    level={2}
                    style={{ margin: 0, color: isEarn ? `#52c41a` : `red` }}
                  >
                    {isEarn ? `เพิ่มคะแนน` : `ลดคะแนน`}
                  </Title>
                  <Title
                    level={1}
                    style={{ margin: 0, color: isEarn ? `#52c41a` : `red` }}
                  >
                    {point.length > 0
                      ? `${isEarn ? "+" : "-"}${point} คะแนน`
                      : `0 คะแนน`}
                  </Title>
                  {note.length > 0 && (
                    <Text>หมายเหตุ: {note.length > 0 ? `${note}` : `-`}</Text>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row gutter={[24, 0]} className="mb-24">
            <Col xs={24} md={12} xl={12}>
              <Button
                type="primary"
                loading={loading}
                onClick={() => submitPoint()}
                style={{
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <CheckCircleOutlined /> ยืนยัน
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
      <UserInfoModal
        isModalOpen={isUserModalOpen}
        handleCancel={() => setIsUserModalOpen(false)}
        onSelected={(userInfo) => handleUserSelected(userInfo)}
        onUnSelected={() => {
          form.setFieldValue("mobile", "");
          setUserInfoSelected(null);
          setIsUserModalOpen(false);
        }}
      />
    </Row>
  );
}
