import React, { useState, useEffect } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Typography,
  List,
  Badge,
  Avatar,
} from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  getAdminEarnCsvImport,
  getAdminEarnCsvImportDetail,
} from "../services/point";
import { getAllCustomers } from "../services/customers";

const { Text } = Typography;

export default function PointCsv() {
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [transection, setTransection] = useState([]);

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [csvDetailTitle, setCsvDetailTitle] = useState("");
  const [csvDetail, setCsvDetail] = useState([]);

  useEffect(() => {
    InitData();
  }, []);

  const InitData = async () => {
    const respCus = await getAllCustomers();
    if (!respCus._error) {
      setCustomers(respCus.data);
    }

    const resp = await getAdminEarnCsvImport();
    if (!resp._error) {
      const mapData = resp.data.map((v) => {
        return {
          ...v,
          created_at: moment(v.created_at).format("DD-MM-YYYY HH:mm"),
        };
      });
      setTransection(mapData);
    }
  };

  const loadCsvDetail = async (record) => {
    console.log("record", record);
    setCsvDetailTitle(record.name);
    setLoading(true);
    setVisible(true);
    const resp = await getAdminEarnCsvImportDetail(record.ID);
    setLoading(false);
    if (!resp._error) {
      const mapData = resp.data.map((v) => {
        return {
          ...v,
          created_at: moment(v.created_at).format("DD-MM-YYYY HH:mm"),
        };
      });
      setCsvDetail(mapData);
    }
  };

  const handleCancel = () => {
    setLoading(false);
    setVisible(false);
  };

  const columns = [
    {
      title: "#",
      key: "index",
      dataIndex: "index",
      width: 80,
      ellipsis: true,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Typography.Link onClick={() => loadCsvDetail(record)}>
          {record.name}
        </Typography.Link>
      ),
    },
    {
      title: "All Rows",
      key: "count_rows",
      dataIndex: "count_rows",
      width: 120,
      ellipsis: true,
    },
    {
      title: "Success Rows",
      key: "count_success_rows",
      dataIndex: "count_success_rows",
      width: 180,
      ellipsis: true,
    },
    {
      title: "CreatedAt",
      key: "created_at",
      dataIndex: "created_at",
      width: 180,
      ellipsis: true,
    },
  ];

  const renderProfile = (record) => {
    const item = customers.filter((v) => v.mobile === record.mobile);
    if (item.length <= 0) {
      return <Text style={{ color: "red" }}>ไม่พบเบอร์นี้ในระบบ</Text>;
    }
    return (
      <div>
        <List.Item.Meta
          avatar={<Avatar src={item[0].line_user_profile} />}
          title={item[0].line_user_name}
          description={
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Badge
                count={`${item[0].point} คะแนน`}
                style={{
                  backgroundColor: "#1890ff",
                }}
              />
            </div>
          }
        />
      </div>
    );
  };

  const csvDetailcolumns = [
    {
      title: "#",
      key: "index",
      dataIndex: "index",
      width: 80,
      ellipsis: true,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      title: "Profile",
      key: "profile",
      dataIndex: "profile",
      width: 180,
      ellipsis: true,
      render: (id, record, index) => renderProfile(record),
    },
    {
      title: "Mobile",
      key: "mobile",
      dataIndex: "mobile",
      width: 180,
      ellipsis: true,
    },
    {
      title: "Point",
      key: "point",
      dataIndex: "point",
      width: 180,
      ellipsis: true,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 120,
      ellipsis: true,
      render: (id, record, index) =>
        record.status === 1 ? (
          <div style={{ color: "green" }}>Success</div>
        ) : (
          <div style={{ color: "red" }}>Fail</div>
        ),
    },
    {
      title: "Note",
      key: "note",
      dataIndex: "note",
      width: 180,
      ellipsis: true,
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={
              <>
                <Row
                  gutter={[24, 0]}
                  className="ant-row-flex ant-row-flex-middle"
                >
                  <Col xs={24} md={12}>
                    เพิ่มคะแนน (.csv)
                  </Col>
                  <Col xs={24} md={12} className="d-flex">
                    <Button
                      onClick={() => history.push("/point-csv/import")}
                      icon={<PlusCircleOutlined />}
                    >
                      นำเข้าไฟล์ (.csv)
                    </Button>
                  </Col>
                </Row>
              </>
            }
          >
            <div className="table-responsive">
              <Table
                sticky
                dataSource={transection}
                columns={columns}
                className="ant-border-space"
                rowKey="ID"
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        centered
        width="80%"
        open={visible}
        title={csvDetailTitle}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleCancel}
          >
            ปิดหน้าต่าง
          </Button>,
        ]}
      >
        <div className="table-responsive">
          <Table
            sticky
            dataSource={csvDetail}
            columns={csvDetailcolumns}
            className="ant-border-space"
            rowKey="ID"
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
