import React, { useState, useRef, useEffect } from "react";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Table,
  Input,
  Space,
  Button,
  Popconfirm,
  Image,
  Switch,
} from "antd";
import Highlighter from "react-highlight-words";
import { getAllRewards } from "../services/rewards";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { deleteReward, switchReward } from "../services/rewards";

export default function Rewards() {
  const history = useHistory();
  const [rewards, setRewards] = useState([]);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    InitData();
  }, []);

  const InitData = async () => {
    const resp = await getAllRewards();
    if (!resp._error) {
      setRewards(resp.data);
    }
  };

  const onClickEdit = (record) => {
    history.push({
      pathname: "/rewards/update",
      state: { record },
    });
  };

  const removeReward = async (rewardId) => {
    const resp = await deleteReward(rewardId);
    if (!resp._error) {
      const update = rewards.filter((v) => v.ID !== rewardId);
      setRewards(update);
    }
  };

  const rewardSwitchEnable = async (flag, rewardId) => {
    const data = {
      reward_id: rewardId,
      enable: Number(flag),
    };
    await switchReward(data);
  };

  const renderAvatar = (avatar) => {
    if (avatar) {
      return (
        <Image
          style={{
            backgroundColor: "#CCCCCC",
            width: 128,
            height: 80,
            objectFit: "cover",
          }}
          src={avatar}
        />
      );
    }
    return (
      <div
        style={{
          width: 128,
          height: 80,
          backgroundColor: "#CCCCCC",
        }}
      />
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Image",
      dataIndex: "image_cover_url",
      key: "image_cover_url",
      width: 180,
      render: (_, record) =>
        rewards.length >= 1 ? renderAvatar(record.image_cover_url) : null,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      width: 220,
      ellipsis: true,
    },
    {
      title: "Enable",
      dataIndex: "enable",
      key: "enable",
      width: 100,
      render: (_, record) =>
        rewards.length >= 1 && (
          <Switch
            checkedChildren="เปิด "
            unCheckedChildren="ปิด "
            onChange={(checked) => rewardSwitchEnable(checked, record.ID)}
            defaultChecked={record.enable === 1}
          />
        ),
    },
    {
      title: "Use Point",
      key: "use_point",
      dataIndex: "use_point",
      width: 160,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 160,
      ...getColumnSearchProps("amount"),
      sorter: (a, b) => a.amount.localeCompare(b.amount),
      sortOrder: sortedInfo.columnKey === "amount" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Amount Used",
      dataIndex: "amount_used",
      key: "amount_used",
      width: 160,
      sorter: (a, b) => a.amount_used.localeCompare(b.amount_used),
      sortOrder:
        sortedInfo.columnKey === "amount_used" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Per User",
      dataIndex: "is_per_user",
      key: "is_per_user",
      width: 120,
      sorter: (a, b) => a.is_per_user.localeCompare(b.is_per_user),
      sortOrder:
        sortedInfo.columnKey === "is_per_user" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) =>
        rewards.length >= 1 && record.is_per_user === 1 ? "true" : "false",
    },
    {
      title: "Start Datetime",
      key: "start_datetime",
      dataIndex: "start_datetime",
      width: 180,
      sorter: (a, b) => a.start_datetime.localeCompare(b.start_datetime),
      sortOrder:
        sortedInfo.columnKey === "start_datetime" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.start_datetime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "End Datetime",
      key: "end_datetime",
      dataIndex: "end_datetime",
      width: 180,
      sorter: (a, b) => a.end_datetime.localeCompare(b.end_datetime),
      sortOrder:
        sortedInfo.columnKey === "end_datetime" ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) =>
        moment(record.end_datetime).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "Tools",
      dataIndex: "operation",
      fixed: "right",
      width: 178,
      render: (_, record) =>
        rewards.length >= 1 ? (
          <Space>
            <Button
              onClick={() => {
                onClickEdit(record);
              }}
              style={{ width: 60 }}
            >
              แก้ไข
            </Button>
            <Popconfirm
              placement="topRight"
              title={`ต้องการลบรางวัล : ${record.name}`}
              onConfirm={() => {
                removeReward(record.ID);
              }}
              okText="ใช่, ยืนยันลบ"
              cancelText="ยกเลิก"
            >
              <Button style={{ width: 60 }}>ลบ</Button>
            </Popconfirm>
          </Space>
        ) : null,
    },
  ];

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={
              <>
                <Row
                  gutter={[24, 0]}
                  className="ant-row-flex ant-row-flex-middle"
                >
                  <Col xs={24} md={12}>
                    รางวัล
                  </Col>
                  <Col xs={24} md={12} className="d-flex">
                    <Button
                      onClick={() => history.push("/rewards/create")}
                      icon={<PlusCircleOutlined />}
                    >
                      เพิ่มรางวัล
                    </Button>
                  </Col>
                </Row>
              </>
            }
          >
            <div className="table-responsive">
              <Table
                sticky
                dataSource={rewards}
                columns={columns}
                onChange={handleChange}
                scroll={{ x: 1200 }}
                className="ant-border-space"
                rowKey="ID"
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "30"],
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
